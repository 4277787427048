import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { useQuery } from "react-query";
import { useCookies } from "react-cookie";

import { useMyContext } from "../../../../contexts/StateHolder";
import { reactQueryGetAsset } from "../../../../scripts/dataHandlerReactquery";

import classes from "./VideoDetails.module.css";
import settings from "../../../../configs/config_settings.json";

import { getImageByKey } from "../../../../scripts/getImageByKey";
import { ROUTES } from "../../../../constants/route";

import ContentRatings from "../ContentRatings";
import FolderNames from "../FolderNames/FolderNames";
import Button from "../../../Common/Button/Button";
import InfoData from "../InfoData/InfoData";
import VideoDetailsBackground from "./VideoDetailsBackground";
import Share from "../Share/Share";
import useLoginModal from "../../../../hooks/useLoginModal";
import Translations from "../../../Translations";
import Assets from "../../../../pages/Assets";
import ExpandableText from "../../../Common/ExpandableText/ExpandableText";

const VideoDetails = () => {
	const { asset } = useParams();
	const { organizationId, key } = settings.organization;

	const history = useHistory();
	const loginModal = useLoginModal();

	const { user, userLoggedIn, language } = useMyContext();
	const [coverImage, setCoverImage] = useState(null);

	// eslint-disable-next-line no-unused-vars
	const [cookies, setCookie] = useCookies("");

	const fetchAsset = (a, b, c, d, e, f) => {
		console.log("a,b,c,d,e", a, b, c, d, e);
		return reactQueryGetAsset(a, b, c, d, e, null, f); // FIX ME
	};

	const { data, isLoading, isFetching } = useQuery(
		[`getAssets${asset}`, asset, userLoggedIn, cookies?.useMy, language],
		() => fetchAsset(organizationId, language, key, asset, user, cookies?.useMy),
		{
			enabled: asset * 1 > 0,
			// by default the cache time is 5 minutes
			cacheTime: 300000,
			staleTime: Infinity,
		}
	);

	const checkUserAccessForAsset = useCallback(
		(item) => {
			if (item?.accessInfo?.access === 1) {
				// If user has access to product, push to playVideo route
				history.push(`${ROUTES.VIDEO}/${organizationId}/${asset}`);
			} else if (item?.accessInfo?.products) {
				if (item?.accessInfo?.products?.length === 0) {
					// If there's no products (doesnt require payment) for asset, push to playVideo route
					history.push(`${ROUTES.VIDEO}/${organizationId}/${asset}`);
				} else {
					// If there's product available, but user doesnt have access, push to packages route
					setCookie(
						"packageReturn",
						{ returnPath: `${ROUTES.VIDEO}/${organizationId}/${asset}` },
						{ path: "/", secure: true, sameSite: "none" }
					);

					history.push(`${ROUTES.PACKAGES}/asset/${asset}`);
				}
			}
		},
		[asset, history, setCookie, organizationId]
	);

	useEffect(() => {
		if (!isFetching && userLoggedIn && loginModal.isOpen) {
			// IF DATA HAS BEEN FETCHED AGAIN AND WHEN USER IS LOGGED WE CHECK FOR ACCESS
			checkUserAccessForAsset(data?.data);
			loginModal.onClose();
		}
	}, [checkUserAccessForAsset, data?.data, isFetching, loginModal, userLoggedIn]);

	useEffect(() => {
		if (!isLoading) {
			if (data?.data?.coverImageSmall || data?.data?.serie?.coverImageSmall) {
				setCoverImage(
					data?.data.isSerie
						? data?.data?.serie?.coverImageSmall || data?.data.coverImageSmall
						: data?.data.coverImageSmall
				);
			}
		}
	}, [data?.data, isLoading]);

	useEffect(() => {
		console.log(data?.data?.status, "status", data?.data);
		console.log(isLoading);
	}, [data?.data, isLoading]);

	const clickAction = () => {
		if (!user?.loggedIn) {
			// we need show login modal if not logged in

			loginModal.onOpen();

			return;
		}
		checkUserAccessForAsset(data?.data);
	};

	if (data?.data?.status === "error") {
		return history.push("/");
	}

	return (
		!isLoading &&
		asset && (
			<>
				<div className={classes.container}>
					<VideoDetailsBackground asset={data?.data} />
					<div className={classes.secondaryContainer}>
						<div className={classes.imageContainer}>
							{data?.data?.serie?.coverImageSmall ||
								(data?.data.coverImageSmall && (
									<img
										className={classes.coverImage}
										src={coverImage ? coverImage : getImageByKey("fallbackImage")}
										alt="coverImage"
									/>
								))}
						</div>

						<div className={classes.textContainer}>
							<div className={classes.detailsDataContainer}>
								<div className={classes.detailsTitleContainer}>
									<div className={`${classes.detailsTitle} font-600  `}>
										{data?.data?.name ? data?.data.name : data?.data.title}
									</div>

									{data?.data.contentRatings?.length > 0 && (
										<div className={classes.detailsContentRatings}>
											<ContentRatings size={"35px"} asset={data?.data} />
										</div>
									)}
								</div>

								{data?.data.folders && <FolderNames asset={data?.data} />}

								{
									<div className={`${classes.detailsDescriptionContainer} font-300`}>
										<ExpandableText
											text={data?.data?.description || data?.data?.serie?.description}
										/>
									</div>
								}

								<Button
									inverse
									onClick={() => clickAction()}
									style={{
										border: "none",
									}}
								>
									<Translations text="detailsVideo.action" />
								</Button>

								<InfoData asset={data?.data} />

								<Share item={data?.data} />
							</div>
						</div>
					</div>
				</div>
				{/* SHOW SIMILAR ITEMS */}
				{/* ASSET PROPERTY */}
				{/* Ossi-> it cannot be a comma separated list, has to be either "TVOD",
        "SVOD" or empty (in which case returns both) */}

				<div className="container">
					<Assets settings={settings.components.tvodDetailsVideo_Categories_01} />
					{data?.data?.folders?.map((el) => (
						<Assets
							settings={{
								id: "similarItems",
								mode: "mode_1",
								assetProperty:
									data?.data?.properties?.includes("SVOD") &&
									data?.data?.properties?.includes("TVOD")
										? ""
										: data?.data?.properties,

								groupItemId: el?.id,
							}}
						/>
					))}
				</div>
			</>
		)
	);
};

export default VideoDetails;
