import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { useCookies } from "react-cookie";

import { useMyContext } from "../../../contexts/StateHolder";
import { purchasePackage } from "../../../scripts/dataHandlers";
import { ROUTES } from "../../../constants/route";

import Button from "../../Common/Button/Button";
import PackageTitle from "./PackageTitle";
import SelectedPackage from "../SelectedPackage/SelectedPackage";

import classes from "./ReceiptView.module.css";

const ReceiptView = (props) => {
	const { setPackageById } = props;

	const { itemId } = useParams();
	const { t } = useTranslation();

	const history = useHistory();

	const [purchaseState, setPurchaseState] = useState(null);
	const [purchaseError, setPurchaseError] = useState(null);
	const { packages } = useMyContext();

	const [cookies, setCookie] = useCookies("");

	useEffect(() => {
		async function purchasePackageData() {
			let params = queryString.parse(window.location.search);

			const purchaseResponse = await purchasePackage(cookies?.user?.userToken, params);
			if (purchaseResponse.status === "ok") {
				setPurchaseState("success");
				setPackageById(parseInt(params.productId));

				const myTime = new Date();
				//lets make it use my.icareus.com 10 minutes after the purchase
				const expireTime = new Date(myTime.getTime() + 10 * 60 * 1000).getTime();
				setCookie("useMy", expireTime, { path: "/", maxAge: 600 });
			} else {
				//27 is double purchase. We will count it as a success still
				if (purchaseResponse.errorCode === 27) {
					setPurchaseState("success");
					setPackageById(parseInt(params.productId));
				} else {
					setPurchaseState("error");
					setPurchaseError(t("packages.PurchaseErrorCode" + purchaseResponse.errorCode));
				}
			}
		}

		//If user used voucher we set success to true.
		function voucherPurchase() {
			setPurchaseState("success");
			setPackageById(parseInt(itemId));
		}

		//VOUCHER CASE. If user is coming with voucher the ItemId should only have numbers
		if (/^\d+$/.test(itemId)) {
			voucherPurchase();

			//RETURN FROM BANK OR SIMILAR
		} else {
			purchasePackageData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		//voucher
		if (/^\d+$/.test(itemId)) {
			setPackageById(parseInt(itemId));
			//non voucher
		} else {
			let params = queryString.parse(window.location.search);
			setPackageById(parseInt(params.productId));
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [packages]);

	const moveToOriginalPage = () => {
		console.log(cookies?.packageReturn?.returnPath);
		if (cookies?.packageReturn?.returnPath && purchaseState === "success") {
			history.replace(cookies?.packageReturn?.returnPath);
		} else {
			//if no stored path, then return to frontpage
			history.replace(ROUTES.HOME);
		}
	};

	return (
		<div
			className={"asset-main-container package-container"}
			style={{
				flexDirection: "column",
			}}
		>
			<PackageTitle title={t("packages.PackageReceipt")} active />

			<div
				style={{
					margin: "30px auto",
					display: "flex",
					flexDirection: "column",
				}}
			>
				{purchaseState === "success" && (
					<>
						<div className={classes.receiptTitle}>{t("packages.ReceiptThank")}</div>
						<SelectedPackage successPayment />
						{purchaseError && <div className={classes.receiptFailureMessage}>{purchaseError}</div>}
					</>
				)}
				{purchaseState === "error" && (
					<>
						<div className={classes.receiptTitle}>{t("packages.ReceiptPurchaseFailed")}</div>
						<div className={classes.receiptFailureMessage}>{purchaseError}</div>
					</>
				)}
				<div className={classes.receiptReturnPath}>
					<Button onClick={() => moveToOriginalPage()} inverse>
						{t(
							cookies?.packageReturn?.returnPath && purchaseState === "success"
								? "shared.watch"
								: "packages.ReceiptMoveToFront"
						)}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default ReceiptView;
