import fi_all_ages from "../constants/assets/images/contentRatings/fi_all_ages.png";
import fi_above_7 from "../constants/assets/images/contentRatings/fi_above_7.png";
import fi_above_12 from "../constants/assets/images/contentRatings/fi_above_12.png";
import fi_above_16 from "../constants/assets/images/contentRatings/fi_above_16.png";
import fi_above_18 from "../constants/assets/images/contentRatings/fi_above_18.png";
import fi_distress from "../constants/assets/images/contentRatings/fi_distress.png";
import fi_sex from "../constants/assets/images/contentRatings/fi_sex.png";
import fi_violence from "../constants/assets/images/contentRatings/fi_violence.png";
import fi_drugs from "../constants/assets/images/contentRatings/fi_drugs.png";

import logo from "../constants/assets/images/logo/logo.png";

import channelBannerDemo from "../constants/assets/images/defaultImages/channelBannerDemo.jpeg";

import paymentMethodBambora from "../constants/assets/images/paymentMethods/VismaPay.svg";
import paymentMethodVoucher from "../constants/assets/images/paymentMethods/voucher.svg";

import paymentMethodVisma from "../constants/assets/images/paymentMethods/payment-options-bambora.png";

import guidesDesktop from "../constants/assets/images/defaultImages/guidesDesktop.PNG";
import guidesMobile from "../constants/assets/images/defaultImages/guidesMobile.PNG";
import guidesChromecast from "../constants/assets/images/defaultImages/guidesChromecast.PNG";

import contactus from "../constants/assets/images/defaultIcons/contactus.svg";

import login_background from "../constants/assets/images/auth/login_background.svg";
import signup_background from "../constants/assets/images/auth/signup_background.svg";
import forgot_password from "../constants/assets/images/auth/forgot_password.svg";
import profile_background from "../constants/assets/images/auth/profile_background.svg";
import user_profile from "../constants/assets/images/auth/user_profile.svg";
import no_result from "../constants/assets/images/search/no_result.svg";

import fallbackImage from "../constants/assets/images/defaultImages/fallback_image.png";

import facebook from "../constants/assets/images/some/facebook.svg";
import twitter from "../constants/assets/images/some/twitter.svg";

import svod_banner from "../constants/assets/images/homepage/svod.svg";
import tvod_banner from "../constants/assets/images/homepage/tvod.svg";

const images = {
	fi_all_ages,
	fi_above_7,
	fi_above_12,
	fi_above_16,
	fi_above_18,
	fi_distress,
	fi_sex,
	fi_violence,
	fi_drugs,

	logo,

	login_background,
	signup_background,
	forgot_password,
	profile_background,
	no_result,
	user_profile,

	paymentMethodBambora,
	paymentMethodVoucher,
	paymentMethodVisma,

	guidesDesktop,
	guidesMobile,
	guidesChromecast,

	contactus,

	fallbackImage,

	channelBannerDemo,

	facebook,
	twitter,

	svod_banner,
	tvod_banner,

	// NOT CHECKED FROM HERE
};

const getImageByKey = (key) => {
	return images[key];
};

export { getImageByKey };
